/* eslint-disable @typescript-eslint/no-unused-vars */
"use client";

import { useLayoutEffect } from "react";

import { LoadingScreen } from "@atlas-ui/components";

import { withAuthentication } from "@/lib/common/helpers/with-authentication";

import { useRouter } from "next/navigation";

const Home = () => {
  const router = useRouter();

  useLayoutEffect(() => {
    router.replace("/my-documents");
  }, [router]);

  return <LoadingScreen />;
};

export default withAuthentication(Home, {
  onRedirecting: () => (
    <div className="w-screen h-[calc(100vh-92px)]">
      <LoadingScreen />
    </div>
  ),
});
